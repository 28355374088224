import React from "react";
import { graphql } from "gatsby";
import LayoutPatient from "components/layout/patient";
import HeaderPatientTemplates from "components/section/headerPatientTemplates";
import { Row, Col, Grid, Timeline, Avatar, Tag } from "rsuite";
import SectionWrapper from "../../components/section/sectionWrapper";
import ContentfulRelatedContent from "../../components/section/contentfulRelatedContent";
import TsnRequest from "../../components/section/TsnRequest";
import Markdown from "markdown-to-jsx";
import { Tooltip, Alert } from "antd";

const ContentfulPageAnticoagulant = ({ data }) => {
  return (
    <LayoutPatient>
      <HeaderPatientTemplates
        title={data.contentfulPageAnticoagulant.title}
        tag="Antistollingsmiddel"
      />
      <SectionWrapper padded background>
        <Grid>
          <Row style={{ fontSize: "18px" }}>
            <Col className="markdown">
              <Markdown
                children={data.contentfulPageAnticoagulant.mdxText.mdxText}
                options={{
                  overrides: {
                    Alert: {
                      component: Alert,
                    },
                    Tooltip: {
                      component: Tooltip,
                    },
                  },
                }}
              />
            </Col>
          </Row>
        </Grid>
      </SectionWrapper>
      {data.contentfulPageAnticoagulant.expert1Name && (
        <SectionWrapper padded>
          <Grid>
            <Row>
              <Col xs={24}>
                <h3 style={{ marginBottom: "20px" }}>
                  Anderen over deze situatie
                </h3>
              </Col>
              <Col xs={2}>
                <Avatar circle style={{ background: "#000" }}>
                  {data.contentfulPageAnticoagulant.expert1Name}
                </Avatar>
              </Col>
              <Col xs={22}>
                <Tag style={{ marginBottom: "5px" }}>
                  {data.contentfulPageAnticoagulant.expert1Title}
                </Tag>
                <h5 style={{ marginBottom: "5px" }}>
                  {" "}
                  {data.contentfulPageAnticoagulant.expert1Name}
                </h5>
                <p>{data.contentfulPageAnticoagulant.expert1Text}</p>
              </Col>
            </Row>
          </Grid>
        </SectionWrapper>
      )}
      <TsnRequest />
      <ContentfulRelatedContent
        items={data.contentfulPageAnticoagulant.related}
      />
      <SectionWrapper padded background>
        <Grid>
          <Row>
            <Col>
              <Timeline>
                <Timeline.Item>
                  Toegevoegd:{" "}
                  {!data.contentfulPageAnticoagulant.updatedAt && (
                    <>{data.contentfulPageAnticoagulant.createdAt}</>
                  )}
                  {data.contentfulPageAnticoagulant.updatedAt && (
                    <>{data.contentfulPageAnticoagulant.updatedAt}</>
                  )}
                </Timeline.Item>
              </Timeline>
            </Col>
          </Row>
        </Grid>
      </SectionWrapper>
    </LayoutPatient>
  );
};

export const data = graphql`
  query pageQuery($id: String) {
    contentfulPageAnticoagulant(id: { eq: $id }) {
      url
      title
      id
      updatedAt(formatString: "DD MMMM YYYY", locale: "nl")
      createdAt(formatString: "DD MMMM YYYY", locale: "nl")
      mdxText {
        mdxText
      }
      related {
        title
        url
        category
      }
    }
  }
`;

export default ContentfulPageAnticoagulant;
