import React from "react";

export default function TsnRequest() {
  return (
    <div>
      <a
        href="https://www.trombosestichting.nl/bestellen/antistollingspas/?utm_source=kennisplatform-antistolling"
        target="_blank"
        rel="noreferrer noopener"
        style={{ color: "white" }}
      >
        <img
          src="/images/pas.png"
          alt="Logo Trombosestichting Nederland"
          style={{
            width: "100%",
            height: "100%"
          }}
        />
      </a>
    </div>
  );
}
